import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalAreas } from '../../api';
import { AreaSchema } from '../../api/schemas/areaSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import GlobalAreaCreateButton from '../../features/global-area/GlobalAreaCreateButton';
import GlobalAreaDetailLink from '../../features/global-area/GlobalAreaDetailLink';
import GlobalAreaDropdown from '../../features/global-area/GlobalAreaDropdown';
import GlobalAreaFilter from '../../features/global-area/GlobalAreaFilter';
import GlobalAreaStatus from '../../features/global-area/GlobalAreaStatus';
import { getCurrency, getNanos, getUnits } from '../../features/global-area/helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.area>;

const GlobalAreasPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { areaId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.areas, urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadGlobalAreas({ ...urlParams, ...params }),
  });

  const { data, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<AreaSchema>({
    queryKey,
    domain: 'Area',
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.areas')}
      extra={
        <PageControls>
          <GlobalAreaCreateButton />
        </PageControls>
      }
      addon={<Outlet />}
      filter={<GlobalAreaFilter />}
    >
      <PageTop loading={loading} meta={meta} />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              title: t('common.name'),
              render: ({ values }) => <GlobalAreaDetailLink data={values} />,
            },
            {
              title: t('common.status'),
              render: ({ values }) => <GlobalAreaStatus data={values} />,
            },
            {
              title: t('common.currency'),
              render: ({ values }) => getCurrency(values),
            },
            {
              title: t('common.units'),
              render: ({ values }) => getUnits(values),
            },
            {
              title: t('common.nanos'),
              render: ({ values }) => getNanos(values),
            },
            {
              render: ({ values }) => <GlobalAreaDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === areaId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalAreaDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalAreaDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.currency')}>{getCurrency(item)}</Card.Field>
                <Card.Field label={t('common.units')}>{getUnits(item)}</Card.Field>
                <Card.Field label={t('common.nanos')}>{getNanos(item)}</Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalAreasPage;
