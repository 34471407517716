import { ReactNode } from 'react';
import {
  ACTION_TYPE,
  INSTANCE_TYPE,
  NotificationSchema,
} from '../../api/schemas/notificationSchema';
import { NotificationsSettingSchema } from '../../api/schemas/notificationsSettingSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import routes from '../../config/routes';
import { t } from '../../i18n';
import { addWhen } from '../../utils';
import { can } from '../../utils/permissions';
import {
  checkIsAccountsVisible,
  checkIsClubManagersVisible,
  checkWorkspaceHasChannelsAccess,
} from '../../utils/workspace';
import Link from '../Link';

export const ACTION_TYPE_FILTER_LABELS: Record<NotificationSchema['action_type'], string> = {
  [ACTION_TYPE.UNKNOWN]: '—',
  [ACTION_TYPE.NOTE_CREATED]: t('common.account_note_created'),
  [ACTION_TYPE.NOTE_UPDATED]: t('common.account_note_updated'),
  [ACTION_TYPE.ACCOUNT_MOVED]: t('common.account_moved'),
  [ACTION_TYPE.CM_MANAGER_STOPPED]: t('common.manager_stopped'),
  [ACTION_TYPE.CHANNEL_ACTIVATED]: t('common.channel_activated'),
  [ACTION_TYPE.CHANNEL_DEACTIVATED]: t('common.channel_deactivated'),
  [ACTION_TYPE.CM_TRANSACTION_FAILED]: t('common.transaction_failed'),
  [ACTION_TYPE.ANNOUNCEMENT_CHANGED]: t('common.announcements'),
  [ACTION_TYPE.ACCOUNT_START_FAILED]: t('common.account_failed_start'),
  [ACTION_TYPE.ACCOUNT_CRITICAL_ERROR]: t('common.account_critical_error'),
  [ACTION_TYPE.NEW_AREA_CREATED]: t('common.area_created'),
};

export const ACTION_TYPE_SETTINGS_LABELS: Record<NotificationSchema['action_type'], string> = {
  [ACTION_TYPE.UNKNOWN]: '—',
  [ACTION_TYPE.NOTE_CREATED]: t('common.note_created'),
  [ACTION_TYPE.NOTE_UPDATED]: t('common.note_updated'),
  [ACTION_TYPE.ACCOUNT_MOVED]: t('common.moved'),
  [ACTION_TYPE.CM_MANAGER_STOPPED]: t('common.manager_stopped'),
  [ACTION_TYPE.CHANNEL_ACTIVATED]: t('common.activated'),
  [ACTION_TYPE.CHANNEL_DEACTIVATED]: t('common.deactivated'),
  [ACTION_TYPE.CM_TRANSACTION_FAILED]: t('common.transaction_failed'),
  [ACTION_TYPE.ANNOUNCEMENT_CHANGED]: t('common.announcements'),
  [ACTION_TYPE.ACCOUNT_START_FAILED]: t('common.failed_start'),
  [ACTION_TYPE.ACCOUNT_CRITICAL_ERROR]: t('common.critical_error'),
  [ACTION_TYPE.NEW_AREA_CREATED]: t('common.area_created'),
};

type ActionTypesGroups = {
  label: string;
  action_types: NotificationsSettingSchema['action_types'];
};

export const getActionTypesGroups = (workspaceId: WorkspaceSchema['id']): ActionTypesGroups[] => [
  ...addWhen(
    {
      label: t('common.accounts'),
      action_types: [
        ACTION_TYPE.ACCOUNT_CRITICAL_ERROR,
        ACTION_TYPE.ACCOUNT_MOVED,
        ACTION_TYPE.ACCOUNT_START_FAILED,
        ACTION_TYPE.NOTE_CREATED,
        ACTION_TYPE.NOTE_UPDATED,
      ],
    },
    checkIsAccountsVisible(workspaceId)
  ),
  ...addWhen(
    {
      label: t('common.channels'),
      action_types: [ACTION_TYPE.CHANNEL_ACTIVATED, ACTION_TYPE.CHANNEL_DEACTIVATED],
    },
    checkWorkspaceHasChannelsAccess(workspaceId)
  ),
  ...addWhen(
    {
      label: t('common.club_manager'),
      action_types: [ACTION_TYPE.CM_MANAGER_STOPPED, ACTION_TYPE.CM_TRANSACTION_FAILED],
    },
    checkIsClubManagersVisible(workspaceId)
  ),
  ...addWhen(
    {
      label: t('common.clubs'),
      action_types: [ACTION_TYPE.ANNOUNCEMENT_CHANGED],
    },
    can.club.read
  ),
];

export const getActionTypeContent = (values: NotificationSchema) => {
  const { action_type, workspace, instance } = values;

  const INSTANCE_TYPE_ROUTES: Record<NotificationSchema['instance']['type'], string> = {
    [INSTANCE_TYPE.UNKNOWN]: routes.error404,
    [INSTANCE_TYPE.ACCOUNT]: workspace
      ? routes.workspaceAccount({
          workspaceId: workspace.id,
          accountId: instance.id,
        })
      : routes.account({ accountId: instance.id }),
    [INSTANCE_TYPE.IDENTITY]: workspace
      ? routes.workspaceIdentity({
          workspaceId: workspace.id,
          identityId: instance.id,
        })
      : routes.error404,
    [INSTANCE_TYPE.CHANNEL]: workspace
      ? routes.workspaceChannel({
          workspaceId: workspace.id,
          channelId: instance.id,
        })
      : routes.error404,
    [INSTANCE_TYPE.USER]: workspace
      ? routes.workspaceUser({
          workspaceId: workspace.id,
          userId: instance.id,
        })
      : routes.user({ userId: instance.id }),
    [INSTANCE_TYPE.CM_MANAGER]: workspace
      ? routes.workspaceClubManager({
          workspaceId: workspace.id,
          managerId: instance.id,
        })
      : routes.error404,
    [INSTANCE_TYPE.CM_TRANSACTION]: workspace
      ? routes.workspaceClubTransaction({
          workspaceId: workspace.id,
          transactionId: instance.id,
        })
      : routes.error404,
    [INSTANCE_TYPE.ANNOUNCEMENT]: routes.club({ clubId: instance.id }),
    [INSTANCE_TYPE.AGENT]: routes.agent({ agentId: instance.id }),
    [INSTANCE_TYPE.DEAL]: workspace
      ? routes.workspaceDeal({
          workspaceId: workspace.id,
          dealId: instance.id,
        })
      : routes.deal({
          dealId: instance.id,
        }),
    [INSTANCE_TYPE.CLUB]: routes.club({ clubId: instance.id }),
    [INSTANCE_TYPE.AREA]: routes.area({ areaId: instance.id }),
  };

  const getLink = () => {
    if (instance.type === INSTANCE_TYPE.CM_TRANSACTION) {
      return (
        <Link target="_blank" to={INSTANCE_TYPE_ROUTES[instance.type]}>
          {String(t('common.transaction'))}
        </Link>
      );
    }

    return (
      <Link
        target="_blank"
        params={false}
        to={`${INSTANCE_TYPE_ROUTES[instance.type]}?search=${instance.id}`}
      >
        {instance.displayed_name}
      </Link>
    );
  };

  const link = getLink();

  const ACTION_TYPE_CONTENT: Record<NotificationSchema['action_type'], ReactNode> = {
    [ACTION_TYPE.UNKNOWN]: '—',
    [ACTION_TYPE.NOTE_CREATED]: (
      <>
        {t('sentences.note_has_been_added')} {link}
      </>
    ),
    [ACTION_TYPE.NOTE_UPDATED]: (
      <>
        {t('sentences.note_has_been_edited')} {link}
      </>
    ),
    [ACTION_TYPE.ACCOUNT_MOVED]: (
      <>
        {link} {t('sentences.has_been_moved')}
      </>
    ),
    [ACTION_TYPE.CM_MANAGER_STOPPED]: (
      <>
        {link} {t('sentences.has_been_stopped')}
      </>
    ),
    [ACTION_TYPE.CHANNEL_ACTIVATED]: (
      <>
        {link} {t('sentences.has_been_activated')}
      </>
    ),
    [ACTION_TYPE.CHANNEL_DEACTIVATED]: (
      <>
        {link} {t('sentences.has_been_deactivated')}
      </>
    ),
    [ACTION_TYPE.CM_TRANSACTION_FAILED]: (
      <>
        {link} {t('sentences.has_been_failed')}
      </>
    ),
    [ACTION_TYPE.ANNOUNCEMENT_CHANGED]: (
      <>
        {t('sentences.the_announcements_at_the_club')} {link} {t('sentences.have_been_changed')}
      </>
    ),
    [ACTION_TYPE.ACCOUNT_START_FAILED]: (
      <>
        {link} {t('sentences.failed_to_start')}
      </>
    ),
    [ACTION_TYPE.ACCOUNT_CRITICAL_ERROR]: (
      <>
        {link} {t('sentences.critical_error')}
      </>
    ),
    [ACTION_TYPE.NEW_AREA_CREATED]: (
      <>
        {t('common.area')} {link} {t('sentences.has_been_created')}
      </>
    ),
  };

  return ACTION_TYPE_CONTENT[action_type];
};
