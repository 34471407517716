import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { LobbySchema } from '../../api/schemas/lobbySchema';
import Collapse from '../../components/Collapse';
import Empty from '../../components/Empty';
import PageAddon from '../../components/PageAddon';
import routes from '../../config/routes';
import { RouteParams } from '../../utils/generics';
import GlobalLobbyDetailAccount from './GlobalLobbyDetailAccount';
import GlobalLobbyDetailGeneralTab from './GlobalLobbyDetailGeneralTab';

type Params = RouteParams<typeof routes.lobbyTableAccount>;

interface Props {
  data: LobbySchema;
  onClose: () => void;
}

const GlobalLobbyDetail = (props: Props) => {
  const { data, onClose } = props;

  const { t } = useTranslation();
  const { accountId } = useParams<Params>();

  const { table, accounts } = data;

  const getAccounts = () => {
    if (!accounts.length) return <Empty />;

    return (
      <Collapse.Group>
        {accounts.map((item) => (
          <GlobalLobbyDetailAccount
            key={item.id}
            data={item}
            tableId={table.id}
            active={accountId === item.id}
          />
        ))}
      </Collapse.Group>
    );
  };

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        title={table.id}
        onClose={onClose}
        tabs={[
          {
            label: t('common.general'),
            content: <GlobalLobbyDetailGeneralTab data={data} />,
          },
          {
            label: t('common.accounts'),
            content: getAccounts(),
            default: accounts.length > 0,
            count: accounts.length,
            disabled: !accounts.length,
            ...(!accounts.length && { tooltip: t('common.no_accounts') }),
          },
        ]}
      />
    </PageAddon.Group>
  );
};

export default GlobalLobbyDetail;
