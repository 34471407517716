import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';

export const STATUS = {
  UNKNOWN: 0,
  NEW: 1,
  VERIFIED: 2,
} as const;

const areaSchema = z.object({
  id: z.string(),
  name: z.string(),
  status: z.nativeEnum(STATUS),
  area_rates: z.array(
    z.object({
      rate: z.object({
        currency_code: z.string(),
        units: z.number(),
        nanos: z.number(),
      }),
      active_date_time_from: z.string().or(z.null()),
      active_date_time_to: z.string().or(z.null()),
    })
  ),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
  is_deleted: z.boolean(),
});

export type AreaSchema = z.infer<typeof areaSchema>;

export default areaSchema;
