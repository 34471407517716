import { useTranslation } from 'react-i18next';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { can } from '../../utils/permissions';
import { TRAINER_GAME_TYPE_LABELS } from '../../utils/trainer';
import useActions from './useActions';

interface Args {
  data: BotSettingSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, game_type, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { deleteBotSetting } = useActions(args);

  return {
    title: TRAINER_GAME_TYPE_LABELS[game_type],
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.botSettingEdit({ botSettingId: id })),
        disabled: !can.botSetting.createUpdate,
        ...(!can.botSetting.createUpdate && { tooltip: t('common.no_permission') }),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: deleteBotSetting,
        disabled: !can.botSetting.delete,
        ...(!can.botSetting.delete && { tooltip: t('common.no_permission') }),
      },
    },
  };
};

export default useDropdown;
