import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from '../Select';
import { Option, Props as SelectProps } from '../types';
import styles from './styles.module.scss';

type Props<T extends FieldValues, Name extends string> = UseControllerProps<T> &
  SelectProps<FieldValues[Name]> & {
    onSubmit?: () => void;
    onChange?: (option: Option) => void;
  };

const SelectQuick = <T extends FieldValues, Name extends string>(props: Props<T, Name>) => {
  const {
    name,
    control,
    rules,
    onSubmit,
    label,
    options,
    disabled,
    clearable = false,
    onChange,
    ...rest
  } = props;

  const { t } = useTranslation();

  const {
    field,
    formState: { errors, isValid },
  } = useController({ name, control, rules });

  if (disabled) {
    return (
      <div data-quick className={styles.value}>
        {field.value.label}
      </div>
    );
  }

  const error = errors[name];

  return (
    <Select
      quick
      clearable={clearable}
      placeholder={t('common.select')}
      label={label}
      options={options}
      value={field.value}
      onChange={(value) => {
        field.onChange(value);

        if (onChange) onChange(value);

        if (onSubmit && isValid) onSubmit();
      }}
      errorVariant="popover"
      {...(error && { error: String(error.message) })}
      {...rest}
    />
  );
};

export default SelectQuick;
