import { useTranslation } from 'react-i18next';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { can } from '../../utils/permissions';
import useActions from './useActions';

interface Args {
  data: GlobalClubSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, code, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { deleteClub } = useActions({ data });

  return {
    title: code,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.clubEdit({ clubId: id })),
        disabled: !can.club.createUpdate,
        ...(!can.club.createUpdate && { tooltip: t('common.no_permission') }),
      },
      notes: {
        icon: icon('notes', 20),
        label: t('common.notes'),
        onClick: () => navigate(routes.clubNotes({ clubId: id })),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: deleteClub,
        disabled: !can.club.delete,
        ...(!can.club.delete && { tooltip: t('common.no_permission') }),
      },
    },
  };
};

export default useDropdown;
