import { useTranslation } from 'react-i18next';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { can } from '../../utils/permissions';

const GlobalBotSettingRuleCreateButton = () => {
  const { t } = useTranslation();
  const { navigate } = useURL();

  const handleClick = () => navigate(routes.botSettingsRulesCreate);

  if (!can.botSetting.ruleCreateUpdate) return null;

  return <PageCreateButton onClick={handleClick}>{t('common.usage_rule')}</PageCreateButton>;
};

export default GlobalBotSettingRuleCreateButton;
