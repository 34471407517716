import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalAccounts } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import Card from '../../components/Card';
import FadingText from '../../components/FadingText';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import AccountPilotButton from '../../features/account/AccountPilotButton';
import AccountPilotView from '../../features/account/AccountPilotView';
import AccountStatus from '../../features/account/AccountStatus';
import GlobalAccountDetailLink from '../../features/global-account/GlobalAccountDetailLink';
import GlobalAccountDropdown from '../../features/global-account/GlobalAccountDropdown';
import GlobalAccountFilter from '../../features/global-account/GlobalAccountFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { can } from '../../utils/permissions';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.account>;

const GlobalAccountsPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { accountId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.accounts, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalAccounts({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<GlobalAccountSchema>({
    queryKey,
    domain: 'GlobalAccount',
    updateAction: [
      'Edited',
      'PilotSwitchOn',
      'PilotSwitchOff',
      'ConfirmPilotStarted',
      'ConfirmPilotFinished',
      'AccountIsOnline',
      'AccountIsOffline',
    ],
    select: (item) => item,
    enabled: () => !loading,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.accounts')}
      tabs={[
        { to: routes.accounts, label: t('common.accounts'), active: true },
        ...addWhen({ to: routes.sessions, label: t('common.sessions') }, can.session.read),
      ]}
      filter={<GlobalAccountFilter total={total} />}
      extra={<PageControls />}
      addon={<Outlet />}
    >
      <PageTop
        total={total}
        loading={loading}
        {...(isPhone && {
          sorting: [
            { key: 'mga_id', title: t('common.mga_id'), default: true },
            { key: 'is_online', title: t('common.online') },
            { key: 'maintenance.name', title: t('common.workspace') },
          ],
        })}
        meta={meta}
      />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'mga_id',
              title: t('common.mga_id'),
              sorting: true,
              render: ({ values }) => <GlobalAccountDetailLink data={values} />,
            },
            {
              key: 'is_online',
              title: t('common.status'),
              sorting: true,
              render: ({ values }) => <AccountStatus data={values} />,
            },
            {
              key: 'maintenance.name',
              title: t('common.workspace'),
              sorting: true,
              render: ({ values }) => <FadingText text={values.maintenance.name} />,
            },
            {
              render: ({ values }) => (
                <div className={styles.buttons}>
                  <AccountPilotView data={values} />
                  <AccountPilotButton data={values} />
                  <GlobalAccountDropdown data={values} />
                </div>
              ),
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === accountId}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card key={item.id} highlighted={checkIsCreated(item.id)} {...checkIsInView(index)}>
              <Card.Top>
                <GlobalAccountDetailLink data={item} />
                <Card.Top.Controls>
                  <AccountPilotView data={item} />
                  <AccountPilotButton data={item} />
                  <GlobalAccountDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.status')}>
                  <AccountStatus data={item} />
                </Card.Field>
                <Card.Field label={t('common.workspace')}>
                  <FadingText text={item.maintenance.name} />
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalAccountsPage;
