import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalAgents } from '../../api';
import { AgentSchema } from '../../api/schemas/agentSchema';
import Card from '../../components/Card';
import FadingText from '../../components/FadingText';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import GlobalAgentCreateButton from '../../features/global-agent/GlobalAgentCreateButton';
import GlobalAgentDetailLink from '../../features/global-agent/GlobalAgentDetailLink';
import GlobalAgentDropdown from '../../features/global-agent/GlobalAgentDropdown';
import GlobalAgentFilter from '../../features/global-agent/GlobalAgentFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { formatRelativeDate } from '../../utils/date';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.agent>;

const GlobalAgentsPage = () => {
  const { t } = useTranslation();
  const { urlParams } = useURL();
  const { search } = urlParams;
  const { agentId } = useParams<Params>();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();

  const queryKey = [queryKeys.agents, urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadGlobalAgents({ ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<AgentSchema>({
    queryKey,
    domain: 'GlobalAgent',
    updateAction: ['Edited', 'NoteAdded', 'NoteRemoved'],
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.agents')}
      tabs={[
        { to: routes.deals, label: t('common.deals') },
        { to: routes.agents, label: t('common.agents'), active: true },
      ]}
      filter={<GlobalAgentFilter />}
      extra={
        <PageControls>
          <GlobalAgentCreateButton />
        </PageControls>
      }
      addon={<Outlet />}
    >
      {isPhone && (
        <PageTop
          sorting={[
            { key: 'name', title: t('common.name'), default: true },
            { key: 'created_on', title: t('common.created') },
          ]}
        />
      )}
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              title: t('common.name'),
              sorting: true,
              render: ({ values }) => <GlobalAgentDetailLink data={values} />,
            },
            {
              title: t('common.contacts'),
              render: ({ values }) => <FadingText text={values.contact || '—'} />,
            },
            {
              render: ({ values }) => <GlobalAgentDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === agentId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalAgentDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalAgentDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.contacts')}>{item.contact || '—'}</Card.Field>
                <Card.Field label={t('common.created')}>
                  {formatRelativeDate(item.created_on)}
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalAgentsPage;
