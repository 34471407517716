import { loadGlobalClubWorkspaces } from '../../api';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import queryKeys from '../../config/queryKeys';
import usePaginatedData from '../../hooks/usePaginatedData';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { can } from '../../utils/permissions';
import {
  createInfinitePaginatedQueryData,
  deleteInfinitePaginatedQueryData,
} from '../../utils/queryClient';

export const useGlobalClubWorkspacesQuery = (args: {
  clubId: GlobalClubSchema['id'];
  search?: string;
}) => {
  const { clubId, search } = args;

  const queryKey = [queryKeys.clubWorkspaces({ clubId }), search];

  useWebSocketMessage<{
    id: GlobalClubSchema['id'];
    modified_on: GlobalClubSchema['modified_on'];
    maintenance: WorkspaceSchema;
  }>({
    domain: 'GlobalClub',
    onMessage: (message) => {
      const payload = message.payload.maintenance;

      if (message.action === 'MaintenanceAdded') {
        createInfinitePaginatedQueryData(queryKey, payload);
      }

      if (message.action === 'MaintenanceRemoved') {
        deleteInfinitePaginatedQueryData(queryKey, (item) => item.id === payload.id);
      }
    },
    enabled: (message) => message.payload.id === clubId,
  });

  return {
    queryKey,
    ...usePaginatedData({
      queryKey,
      queryFn: ({ page }) =>
        loadGlobalClubWorkspaces(
          { clubId },
          { size: '2000', ...(page && { page }), ...(search && { search }) }
        ),
      enabled: can.workspace.read,
    }),
  };
};
