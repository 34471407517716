import { cloneElement } from 'react';
import ActivityIcon from './activity.svg';
import AddIcon from './add.svg';
import AlertCircleIcon from './alert-circle.svg';
import AlertTriangleIcon from './alert-triangle.svg';
import ArrowChangeIcon from './arrow-change.svg';
import ArrowLongDownIcon from './arrow-long-down.svg';
import ArrowLongTopIcon from './arrow-long-top.svg';
import BoldIcon from './bold.svg';
import BroomIcon from './broom.svg';
import BurgerCloseIcon from './burger-close.svg';
import BurgerMenuIcon from './burger-menu.svg';
import CalendarIcon from './calendar.svg';
import CaseIcon from './case.svg';
import CheckCircleIcon from './check-circle.svg';
import CheckIcon from './check.svg';
import ChevronDownIcon from './chevron-down.svg';
import ChevronLeftIcon from './chevron-left.svg';
import ChevronRightIcon from './chevron-right.svg';
import ChevronUpIcon from './chevron-up.svg';
import ClockBackIcon from './clock-back.svg';
import ClockIcon from './clock.svg';
import CompassIcon from './compass.svg';
import CompassesIcon from './compasses.svg';
import CopyIcon from './copy.svg';
import CornerDownLeftIcon from './corner-down-left.svg';
import CornerDownRightIcon from './corner-down-right.svg';
import CreditCardIcon from './credit-card.svg';
import CrossCircleIcon from './cross-circle.svg';
import CrossIcon from './cross.svg';
import CrosshairIcon from './crosshair.svg';
import CrownIcon from './crown.svg';
import DepositIcon from './deposit.svg';
import DeviceIcon from './device.svg';
import DocumentIcon from './document.svg';
import DocumentsIcon from './documents.svg';
import DollarIcon from './dollar.svg';
import DownloadIcon from './download.svg';
import DragAndDropIcon from './drag-and-drop.svg';
import DualArrowIcon from './dual_arrow.svg';
import EditIcon from './edit.svg';
import EqIcon from './eq.svg';
import ExternalLinkIcon from './external-link.svg';
import EyeOffIcon from './eye-off.svg';
import EyeIcon from './eye.svg';
import FileTextCrossedIcon from './file-text-crossed.svg';
import FileTextIcon from './file-text.svg';
import FilterIcon from './filter.svg';
import FingerprintIcon from './fingerprint.svg';
import FolderIcon from './folder.svg';
import GeIcon from './ge.svg';
import GiftIcon from './gift.svg';
import GlobeCrossedIcon from './globe-crossed.svg';
import GlobeIcon from './globe.svg';
import GteIcon from './gte.svg';
import HashIcon from './hash.svg';
import HelpCircleIcon from './help-circle.svg';
import HomeCrossedIcon from './home-crossed.svg';
import HomeIcon from './home.svg';
import ImageWithManCrossedIcon from './image-with-man-crossed.svg';
import ImageWithManIcon from './image-with-man.svg';
import InIcon from './in.svg';
import InfoOctagonIcon from './info-octagon.svg';
import KeyIcon from './key.svg';
import LeIcon from './le.svg';
import ListIcon from './list.svg';
import LobbyIcon from './lobby.svg';
import LockIcon from './lock.svg';
import LogOutIcon from './log-out.svg';
import LteIcon from './lte.svg';
import MailIcon from './mail.svg';
import MapPinIcon from './map-pin.svg';
import MaximizeIcon from './maximize.svg';
import MinimizeIcon from './minimize.svg';
import MinusCircleIcon from './minus-circle.svg';
import MinusIcon from './minus.svg';
import MonitorIcon from './monitor.svg';
import MoreVerticalIcon from './more-vertical.svg';
import NotEqIcon from './not-eq.svg';
import NotInIcon from './not-in.svg';
import NotesIcon from './notes.svg';
import NotificationIcon from './notification.svg';
import OperatorIcon from './operator.svg';
import PaperclipIcon from './paperclip.svg';
import PassportCrossedIcon from './passport-crossed.svg';
import PassportIcon from './passport.svg';
import PauseCircleIcon from './pause-circle.svg';
import PauseIcon from './pause.svg';
import PercentIcon from './percent.svg';
import PieChartIcon from './pie-chart.svg';
import PlayCircleIcon from './play-circle.svg';
import PlayIcon from './play.svg';
import PokerChipXIcon from './poker-chip-x.svg';
import PokerChipIcon from './poker-chip.svg';
import PokerTableIcon from './poker-table.svg';
import RCircleIcon from './r-circle.svg';
import RefreshIcon from './refresh.svg';
import RotateIcon from './rotate.svg';
import SearchIcon from './search.svg';
import SendIcon from './send.svg';
import SettingsIcon from './settings.svg';
import ShoppingBagOutIcon from './shopping-bag-out.svg';
import ShoppingBagIcon from './shopping-bag.svg';
import ShoppingCartCrossedIcon from './shopping-cart-crossed.svg';
import ShoppingCartIcon from './shopping-cart.svg';
import SlidersIcon from './sliders.svg';
import SpadeMapIcon from './spade-map.svg';
import SpadeMapsIcon from './spade-maps.svg';
import StarIcon from './star.svg';
import SwitchIcon from './switch.svg';
import TrashIcon from './trash.svg';
import TriangleDownIcon from './triangle-down.svg';
import TriangleUpIcon from './triangle-up.svg';
import UnlockIcon from './unlock.svg';
import UploadIcon from './upload.svg';
import UserCircleIcon from './user-circle.svg';
import UserMinusIcon from './user-minus.svg';
import UserXIcon from './user-x.svg';
import UserIcon from './user.svg';
import UsersIcon from './users.svg';
import WheelCrossedIcon from './wheel-crossed.svg';
import WheelIcon from './wheel.svg';
import WithdrawIcon from './withdraw.svg';
import XCircleIcon from './x-circle.svg';

export const icons = {
  activity: <ActivityIcon />,
  add: <AddIcon />,
  alertCircle: <AlertCircleIcon />,
  alertTriangle: <AlertTriangleIcon />,
  arrowChange: <ArrowChangeIcon />,
  arrowLongDown: <ArrowLongDownIcon />,
  arrowLongTop: <ArrowLongTopIcon />,
  bold: <BoldIcon />,
  broom: <BroomIcon />,
  burgerClose: <BurgerCloseIcon />,
  burgerMenu: <BurgerMenuIcon />,
  calendar: <CalendarIcon />,
  case: <CaseIcon />,
  checkCircle: <CheckCircleIcon />,
  check: <CheckIcon />,
  chevronDown: <ChevronDownIcon />,
  chevronLeft: <ChevronLeftIcon />,
  chevronRight: <ChevronRightIcon />,
  chevronUp: <ChevronUpIcon />,
  clockBack: <ClockBackIcon />,
  clock: <ClockIcon />,
  compass: <CompassIcon />,
  compasses: <CompassesIcon />,
  copy: <CopyIcon />,
  cornerDownLeft: <CornerDownLeftIcon />,
  cornerDownRight: <CornerDownRightIcon />,
  creditCard: <CreditCardIcon />,
  crossCircle: <CrossCircleIcon />,
  cross: <CrossIcon />,
  crosshair: <CrosshairIcon />,
  crown: <CrownIcon />,
  deposit: <DepositIcon />,
  device: <DeviceIcon />,
  document: <DocumentIcon />,
  documents: <DocumentsIcon />,
  dollar: <DollarIcon />,
  download: <DownloadIcon />,
  dragAndDrop: <DragAndDropIcon />,
  dualArrow: <DualArrowIcon />,
  edit: <EditIcon />,
  eq: <EqIcon />,
  externalLink: <ExternalLinkIcon />,
  eyeOff: <EyeOffIcon />,
  eye: <EyeIcon />,
  fileTextCrossed: <FileTextCrossedIcon />,
  fileText: <FileTextIcon />,
  filter: <FilterIcon />,
  fingerprint: <FingerprintIcon />,
  folder: <FolderIcon />,
  ge: <GeIcon />,
  gift: <GiftIcon />,
  globeCrossed: <GlobeCrossedIcon />,
  globe: <GlobeIcon />,
  gte: <GteIcon />,
  hash: <HashIcon />,
  helpCircle: <HelpCircleIcon />,
  homeCrossed: <HomeCrossedIcon />,
  home: <HomeIcon />,
  imageWithManCrossed: <ImageWithManCrossedIcon />,
  imageWithMan: <ImageWithManIcon />,
  in: <InIcon />,
  infoOctagon: <InfoOctagonIcon />,
  key: <KeyIcon />,
  le: <LeIcon />,
  list: <ListIcon />,
  lobby: <LobbyIcon />,
  lock: <LockIcon />,
  logOut: <LogOutIcon />,
  lte: <LteIcon />,
  mail: <MailIcon />,
  mapPin: <MapPinIcon />,
  maximize: <MaximizeIcon />,
  minimize: <MinimizeIcon />,
  minusCircle: <MinusCircleIcon />,
  minus: <MinusIcon />,
  monitor: <MonitorIcon />,
  moreVertical: <MoreVerticalIcon />,
  notEq: <NotEqIcon />,
  notIn: <NotInIcon />,
  notes: <NotesIcon />,
  notification: <NotificationIcon />,
  operator: <OperatorIcon />,
  paperclip: <PaperclipIcon />,
  passportCrossed: <PassportCrossedIcon />,
  passport: <PassportIcon />,
  pauseCircle: <PauseCircleIcon />,
  pause: <PauseIcon />,
  percent: <PercentIcon />,
  pieChart: <PieChartIcon />,
  playCircle: <PlayCircleIcon />,
  play: <PlayIcon />,
  pokerChipX: <PokerChipXIcon />,
  pokerChip: <PokerChipIcon />,
  pokerTable: <PokerTableIcon />,
  rCircle: <RCircleIcon />,
  refresh: <RefreshIcon />,
  rotate: <RotateIcon />,
  search: <SearchIcon />,
  send: <SendIcon />,
  settings: <SettingsIcon />,
  shoppingBagOut: <ShoppingBagOutIcon />,
  shoppingBag: <ShoppingBagIcon />,
  shoppingCartCrossed: <ShoppingCartCrossedIcon />,
  shoppingCart: <ShoppingCartIcon />,
  sliders: <SlidersIcon />,
  spadeMap: <SpadeMapIcon />,
  star: <StarIcon />,
  spadeMaps: <SpadeMapsIcon />,
  switch: <SwitchIcon />,
  trash: <TrashIcon />,
  triangleDown: <TriangleDownIcon />,
  triangleUp: <TriangleUpIcon />,
  unlock: <UnlockIcon />,
  upload: <UploadIcon />,
  userCircle: <UserCircleIcon />,
  userMinus: <UserMinusIcon />,
  userX: <UserXIcon />,
  user: <UserIcon />,
  users: <UsersIcon />,
  wheelCrossed: <WheelCrossedIcon />,
  wheel: <WheelIcon />,
  withdraw: <WithdrawIcon />,
  xCircle: <XCircleIcon />,
};

const icon = (name: keyof typeof icons, size: number, config: { className?: string } = {}) =>
  cloneElement(icons[name], { width: size, height: size, className: config.className });

export default icon;
