import clsx from 'clsx';
import { useRef, useState } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import Input from '../Input';
import { Props as InputProps } from '../types';
import styles from './styles.module.scss';

type Props<T extends FieldValues> = UseControllerProps<T> &
  InputProps & {
    onSubmit?: () => void;
    hint?: boolean;
  };

const InputQuick = <T extends FieldValues>(props: Props<T>) => {
  const {
    name,
    control,
    rules,
    disabled,
    onSubmit,
    className,
    hint,
    type = 'text',
    extra,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error, isTouched },
  } = useController({ name, control, rules });

  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [visible, setVisible] = useState(false);

  const esc = useRef(false);
  const isDirty = useRef(false);
  const initialValue = useRef<string>(field.value);

  const size = 'small';
  const password = type === 'password';

  const getExtra = () => {
    if (password) {
      return (
        <Button
          size="small"
          variant="light"
          icon={visible ? icon('eyeOff', 16) : icon('eye', 16)}
          onClick={() => setVisible((prev) => !prev)}
        />
      );
    }

    return extra;
  };

  if (disabled && !field.value) return '—';

  return (
    <Input
      data-quick
      size={size}
      disabled={disabled}
      placeholder={t('common.enter')}
      className={clsx(
        styles.input,
        error && styles.error,
        (password || extra) && styles.extra,
        password && !visible && field.value && styles.hidden,
        className
      )}
      onFocus={() => setFocused(true)}
      onClick={(event) => {
        if (!focused) {
          const { length } = event.currentTarget.value;

          event.currentTarget.setSelectionRange(length, length);

          setFocused(true);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          field.onChange(initialValue.current);

          esc.current = true;
          event.currentTarget.blur();
        }

        if (event.key === 'Enter') {
          event.preventDefault();
          event.currentTarget.blur();
        }
      }}
      {...field}
      onBlur={(event) => {
        const blur = () => {
          field.onBlur();
          setVisible(false);
          setFocused(false);
        };

        if (esc.current) {
          blur();

          esc.current = false;
        } else {
          blur();

          if (event.currentTarget.value !== initialValue.current) {
            isDirty.current = true;
          }

          initialValue.current = event.currentTarget.value;
          field.onChange(initialValue.current);

          if (isDirty.current && onSubmit) onSubmit();
        }
      }}
      extra={getExtra()}
      errorVariant="popover"
      {...(hint &&
        focused &&
        field.value !== '' && { extra: <div className={styles.enter}>{t('common.enter')}</div> })}
      {...(error && (onSubmit ? isTouched : true) && { error: String(error.message) })}
      {...rest}
    />
  );
};

export default InputQuick;
