import { useTranslation } from 'react-i18next';
import { LobbyAccountSchema } from '../../../api/schemas/lobbySchema';
import { LobbyTableSchema } from '../../../api/schemas/lobbyTableSchema';
import Collapse from '../../../components/Collapse';
import routes from '../../../config/routes';
import { can } from '../../../utils/permissions';
import AccountCloseTable from '../../account/AccountCloseTable';
import AccountMetrics from '../../account/AccountMetrics';
import { ACCOUNT_STATUS_COLORS, ACCOUNT_STATUS_LABELS } from '../../account/helpers';
import { useCloseGlobalAccountTableMutation } from '../../global-account/mutations';
import styles from './styles.module.scss';

interface Props {
  data: LobbyAccountSchema;
  tableId: LobbyTableSchema['id'];
  active: boolean;
}

const GlobalLobbyDetailAccount = (props: Props) => {
  const { data, tableId, active } = props;

  const { id, mga_id, metrics, account_status } = data;

  const { t } = useTranslation();

  const closeTable = useCloseGlobalAccountTableMutation();

  return (
    <Collapse
      label={mga_id}
      labelBefore={<Collapse.Badge color={ACCOUNT_STATUS_COLORS[account_status]} />}
      caption={<Collapse.Id id={id} />}
      {...(active && { className: styles.active })}
      {...(can.account.read && { to: routes.lobbyTableAccount({ tableId, accountId: id }) })}
    >
      <AccountMetrics data={metrics} />
      <Collapse.InfoField label={t('common.account_status')}>
        {ACCOUNT_STATUS_LABELS[account_status]}
      </Collapse.InfoField>
      <AccountCloseTable
        loading={closeTable.isPending}
        canLeaveTable={can.lobby.leaveTable}
        onClose={() => closeTable.mutate({ accountId: id, tableId })}
      />
    </Collapse>
  );
};

export default GlobalLobbyDetailAccount;
