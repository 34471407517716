import { z } from 'zod';
import botSettingSchema from '../botSettingSchema';
import channelSchema from '../channelSchema';
import dateTimeSchema from '../dateTimeSchema';
import globalClubSchema from '../globalClubSchema';
import metricsSchema from '../metricsSchema';
import { sessionCommonSchema } from '../session';
import workspaceStageSchema from '../stage/workspaceStageSchema';
import trainerGameTypeSchema from '../trainerGameTypeSchema';
import trainerLimitSchema from '../trainerLimitSchema';
import trainerPokerRoomSchema from '../trainerPokerRoomSchema';
import workspaceSchema from '../workspaceSchema';
import workspaceUserSchema from '../workspaceUserSchema';
import accountCommonBalanceSchema from './accountCommonBalanceSchema';

export const ROOM_STATUS = {
  UNKNOWN: 0,
  OFF: 1,
  ON: 2,
} as const;

export const BRAIN_STATUS = {
  UNKNOWN: 0,
  DISCONNECTED: 1,
  CONNECTED: 2,
} as const;

export const TABLE_SIZE = {
  UNKNOWN: 0,
  HEADS_UP: 1,
  SHORT_HANDED: 2,
  FULL_RING: 3,
} as const;

export const PILOT_STATUS = {
  UNKNOWN: 0,
  OFF: 1,
  STARTING: 2,
  ON: 3,
  FINISHING: 4,
} as const;

export const REGISTRATION_STATUS = {
  UNKNOWN: 0,
  NOT_REGISTERED: 1,
  REGISTERED: 2,
  IN_PROGRESS: 3,
  ERROR_RECEIVED: 4,
} as const;

const geoSchema = z.object({
  lat: z.number(),
  lng: z.number(),
});

const clubSchema = globalClubSchema.pick({ id: true, name: true, code: true });

export const balanceSchema = z.object({
  lobby_balance: z
    .object({
      amount: accountCommonBalanceSchema.shape.amount,
      currency: accountCommonBalanceSchema.shape.currency,
    })
    .or(z.null()),
  in_game_balance: z.array(
    z.object({
      id: z.string(),
      amount: accountCommonBalanceSchema.shape.amount,
      currency: accountCommonBalanceSchema.shape.currency,
      league_id: accountCommonBalanceSchema.shape.league_id.or(z.null()),
      league_name: accountCommonBalanceSchema.shape.league_name.or(z.null()),
      club_id: accountCommonBalanceSchema.shape.club_id.or(z.null()),
      club_name: accountCommonBalanceSchema.shape.club_name.or(z.null()),
      table_id: accountCommonBalanceSchema.shape.table_id,
    })
  ),
  club_balance: z.array(
    z.object({
      id: z.string(),
      amount: accountCommonBalanceSchema.shape.amount,
      currency: accountCommonBalanceSchema.shape.currency,
      league_id: accountCommonBalanceSchema.shape.league_id.or(z.null()),
      league_name: accountCommonBalanceSchema.shape.league_name.or(z.null()),
      club_id: accountCommonBalanceSchema.shape.club_id,
      club_name: accountCommonBalanceSchema.shape.club_name.or(z.null()),
    })
  ),
  league_balance: z.array(
    z.object({
      id: z.string(),
      amount: accountCommonBalanceSchema.shape.amount,
      currency: accountCommonBalanceSchema.shape.currency,
      league_id: accountCommonBalanceSchema.shape.league_id,
      league_name: accountCommonBalanceSchema.shape.league_name.or(z.null()),
    })
  ),
  modified_on: dateTimeSchema.or(z.null()),
});

export const accountCommonSchema = z.object({
  id: z.string(),
  room: trainerPokerRoomSchema,
  maintenance: workspaceSchema.pick({
    id: true,
    name: true,
    owner_id: true,
    maintenance_types: true,
    is_deleted: true,
    trainer_tech: true,
  }),
  affiliate_reference_number: z.string().or(z.null()),
  user: workspaceUserSchema.pick({ id: true, username: true }),
  is_online: z.boolean(),
  is_miner: z.boolean(),
  on_verification: z.boolean(),
  on_registration: z.nativeEnum(REGISTRATION_STATUS),
  register_error_message: z.string().or(z.null()),
  channels: z.array(
    channelSchema.pick({
      id: true,
      host: true,
      port: true,
      status: true,
    })
  ),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
  last_online: dateTimeSchema.or(z.null()),
  last_played: dateTimeSchema.or(z.null()),
  max_tables: z.number(),
  table_sizes: z.array(z.nativeEnum(TABLE_SIZE)),
  pilot_status: z.nativeEnum(PILOT_STATUS),
  brain_status: z.nativeEnum(BRAIN_STATUS),
  room_status: z.nativeEnum(ROOM_STATUS),
  geo: geoSchema.or(z.null()),
  clubs: z.array(clubSchema),
  stopped_clubs: z.array(clubSchema),
  metrics: metricsSchema.pick({ hands_played: true, net_win_sum: true, win_rate_bb: true }),
  bot_settings: z.array(
    botSettingSchema.pick({
      id: true,
      name: true,
      game_type: true,
      bot_code: true,
      bot_build: true,
      bot_build_straddle: true,
      bot_code_tight: true,
      bot_build_tight: true,
      params_size: true,
      params_size_hu: true,
    })
  ),
  next_session: sessionCommonSchema.pick({ id: true, start: true, end: true }).or(z.null()),
  display_name: z.string().or(z.null()),
  email: z.string().or(z.null()),
  email_password: z.string().or(z.null()),
  pid: z.string().or(z.null()),
  notes_count: z.number(),
  trainer_version: z.string().or(z.null()),
  comparative_limits: z.array(trainerLimitSchema),
  game_types: z.array(trainerGameTypeSchema),
  balance: balanceSchema.or(z.null()),
  stage: workspaceStageSchema.pick({
    id: true,
    name: true,
    stop_current_session: true,
    is_playable: true,
    clear_channel: true,
    clear_schedule: true,
    order: true,
  }), // TODO: сделать просто workspaceStageSchema
  /*   allocation_of_limits: z.array(z.unknown()), // не используется */
  /*   pool_type: z.number(), // не используется */
});

export const accountCommonFilterValuesSchema = z.object({
  room: z.array(accountCommonSchema.shape.room),
  clubs: z.array(
    z.object({
      id: accountCommonSchema.shape.clubs.element.shape.id,
      name: accountCommonSchema.shape.clubs.element.shape.name,
      code: accountCommonSchema.shape.clubs.element.shape.code,
    })
  ),
  game_types: accountCommonSchema.shape.game_types,
  comparative_limits: accountCommonSchema.shape.comparative_limits,
  table_sizes: z.array(accountCommonSchema.shape.table_sizes.element),
  max_tables: z.object({ min: z.number(), max: z.number() }),
  pilot_status: z.array(accountCommonSchema.shape.pilot_status),
  user: z.array(
    z.object({
      id: accountCommonSchema.shape.user.shape.id,
      name: accountCommonSchema.shape.user.shape.username,
    })
  ),
  stage_name: z.array(accountCommonSchema.shape.stage.pick({ id: true, name: true })),
  bot_settings: z.array(
    z.object({
      id: accountCommonSchema.shape.bot_settings.element.shape.id,
      name: accountCommonSchema.shape.bot_settings.element.shape.name,
    })
  ),
});
