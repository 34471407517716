import { useTranslation } from 'react-i18next';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { can } from '../../utils/permissions';

const GlobalLeagueCreateButton = () => {
  const { t } = useTranslation();
  const { navigate } = useURL();

  const handleClick = () => navigate(routes.leaguesCreate);

  if (!can.club.createUpdate) return null;

  return <PageCreateButton onClick={handleClick}>{t('common.league')}</PageCreateButton>;
};

export default GlobalLeagueCreateButton;
