import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalSuperLeague } from '../../api';
import { GlobalSuperLeagueSchema } from '../../api/schemas/globalSuperLeagueSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';

type Params = RouteParams<typeof routes.superLeague>;

interface Args {
  data: GlobalSuperLeagueSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, code, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { superLeagueId } = useParams<Params>();

  return {
    title: code,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.superLeagueEdit({ superLeagueId: id })),
        disabled: !can.club.createUpdate,
        ...(!can.club.createUpdate && { tooltip: t('common.no_permission') }),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteGlobalSuperLeague({ superLeagueId: id });

          if (id === superLeagueId) {
            navigate(routes.superLeagues);
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
        disabled: !can.club.delete,
        ...(!can.club.delete && { tooltip: t('common.no_permission') }),
      },
    },
  };
};

export default useDropdown;
