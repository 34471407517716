import { AreaSchema } from '../../api/schemas/areaSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';

interface Props {
  data: AreaSchema;
}

const GlobalAreaDetailLink = (props: Props) => {
  const { data } = props;

  const { id, name } = data;

  return <PageDetailLink label={name} to={routes.area({ areaId: id })} />;
};

export default GlobalAreaDetailLink;
