import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalSeatControlRules } from '../../api';
import { SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import GlobalSeatControlRuleDetailLink from '../../features/global-seat-control-rule/GlobalSeatControlRuleDetailLink';
import GlobalSeatControlRuleDropdown from '../../features/global-seat-control-rule/GlobalSeatControlRuleDropdown';
import GlobalSeatControlRuleFilter from '../../features/global-seat-control-rule/GlobalSeatControlRuleFilter';
import GlobalSeatControlRuleIsActive from '../../features/global-seat-control-rule/GlobalSeatControlRuleIsActive';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.seatConrolRule>;

const GlobalSeatControlRulesPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { seatControlRuleId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.seatControlRules, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalSeatControlRules({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<SeatControlRuleSchema>({
    queryKey,
    domain: 'GlobalRule',
    updateAction: ['Edited', 'Activated', 'Deactivated'],
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.seat_control_rules')}
      filter={<GlobalSeatControlRuleFilter total={total} />}
      extra={<PageControls />}
      addon={<Outlet />}
    >
      <PageTop total={total} loading={loading} meta={meta} />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              title: t('common.name'),
              render: ({ values }) => <GlobalSeatControlRuleDetailLink data={values} />,
            },
            {
              title: t('common.workspace'),
              render: ({ values }) => values.maintenance.name,
            },
            {
              title: t('common.status'),
              render: ({ values }) => <GlobalSeatControlRuleIsActive data={values} />,
            },
            {
              render: ({ values }) => <GlobalSeatControlRuleDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === seatControlRuleId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalSeatControlRuleDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalSeatControlRuleDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.workspace')}>{item.maintenance.name}</Card.Field>
                <Card.Field label={t('common.status')}>
                  <GlobalSeatControlRuleIsActive data={item} />
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalSeatControlRulesPage;
