import clsx from 'clsx';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import { copy } from '../../../utils/clipboard';
import Button from '../../Button';
import Confirm from '../../Confirm';
import Tooltip from '../../Tooltip';
import PageAddonQuickFieldGroup from '../PageAddonQuickFieldGroup';
import styles from './styles.module.scss';

interface Props {
  title: string;
  subTitle?: string | number;
  danger?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onDelete?: () => void;
}

const PageAddonQuickField = (props: Props) => {
  const { title, subTitle, danger, disabled, children, onDelete } = props;

  const { t } = useTranslation();

  const [deleting, setDeleting] = useState(false);
  const [element, setElement] = useState<Element | null>(null);

  const ref = useCallback((node: HTMLButtonElement) => {
    if (node) {
      setElement(node);
    }
  }, []);

  const getLabel = () => {
    if (!element) return null;

    if (element.scrollWidth > element.clientWidth) return title;

    return null;
  };

  return (
    <div className={clsx(styles.container, deleting && styles.active)}>
      <div>
        <Tooltip ref={ref} label={getLabel()}>
          <button
            type="button"
            onClick={() => copy(title)}
            className={clsx(styles.button, styles.title, danger && styles.danger)}
          >
            {title}
          </button>
        </Tooltip>
        {subTitle && (
          <button
            type="button"
            onClick={() => copy(subTitle)}
            className={clsx(styles.button, styles.subTitle)}
          >
            {subTitle}
          </button>
        )}
      </div>
      <div className={styles.actions}>
        {children}
        {onDelete && (
          <Tooltip label={t('common.delete')}>
            <Confirm
              onConfirm={onDelete}
              onConfirming={() => setDeleting(true)}
              onCancel={() => setDeleting(false)}
              title={t('sentences.delete_this_record')}
              confirmText={t('common.delete')}
            >
              {({ confirming }) => (
                <Button
                  danger
                  disabled={disabled}
                  focused={confirming}
                  size="extra-small"
                  variant="light"
                  icon={icon('trash', 16)}
                />
              )}
            </Confirm>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

PageAddonQuickField.Group = PageAddonQuickFieldGroup;

export default PageAddonQuickField;
