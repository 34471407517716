import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalClubSchema } from '../../../api/schemas/globalClubSchema';
import Button from '../../../components/Button';
import FadingText from '../../../components/FadingText';
import Link from '../../../components/Link';
import PageAddon from '../../../components/PageAddon';
import routes from '../../../config/routes';
import { can } from '../../../utils/permissions';
import { useGlobalClubContext } from '../GlobalClubContext';
import useActions from '../useActions';
import styles from './styles.module.scss';

interface Props {
  data: GlobalClubSchema;
  onClose: () => void;
}

const GlobalClubDeleteError = (props: Props) => {
  const { data, onClose } = props;

  const { id } = data;

  const { t } = useTranslation();
  const { deleteClub } = useActions({ data });
  const { deleteError, setDeleteError } = useGlobalClubContext();

  if (!deleteError) return null;

  const { accounts } = deleteError;

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        onClose={() => {
          setDeleteError(null);
          onClose();
        }}
        title={<PageAddon.DangerTitle>{t('common.delete_error')}</PageAddon.DangerTitle>}
      >
        <div className={styles.container}>
          <PageAddon.Heading level="second">{t('sentences.club_to_accounts')}</PageAddon.Heading>
          <div className={styles.buttons}>
            {accounts.map((item) => {
              const content = <FadingText text={item.mga_id} className={styles.text} />;

              if (!can.account.read) return content;

              return (
                <Link
                  key={item.id}
                  to={routes.clubErrorAccount({ clubId: id, accountId: item.id })}
                >
                  {content}
                </Link>
              );
            })}
          </div>
        </div>
        <PageAddon.Controls>
          <Button onClick={deleteClub}>{t('common.retry')}</Button>
        </PageAddon.Controls>
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalClubDeleteError;
