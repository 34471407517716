import { useTranslation } from 'react-i18next';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { can } from '../../utils/permissions';

const GlobalDealCreateButton = () => {
  const { t } = useTranslation();
  const { navigate } = useURL();

  const handleClick = () => navigate(routes.dealsCreate);

  if (!can.deal.createUpdate) return null;

  return <PageCreateButton onClick={handleClick}>{t('common.deal')}</PageCreateButton>;
};

export default GlobalDealCreateButton;
